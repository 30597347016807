import React from "react";
import nat4 from "../../assets/img/nat-4.jpg";
import "./tourBox.css";
import MainButton from "../buttons/MainButton";

function TourBox(props) {
  const listItems = props.tour__list.map((elem, i) => {
    return (
      <li className="tour__list-item" key={i}>
        {elem}
      </li>
    );
  });

  const colorListCss = {
    blue: `linear-gradient(to right bottom,rgb(79, 195, 247),rgb(3, 169, 244))`,
    green: `linear-gradient(to right bottom,rgba(126, 213, 111),rgba(40, 180, 133))`,
    red: `linear-gradient(to right bottom, rgb(214, 93, 103),rgb(245, 50, 66))`,
    black: `linear-gradient(to right bottom,rgb(150, 150, 150),rgb(0, 0, 0))`,
  };

  return (
    <div className="tour__main--container">
      <div className="tour__container--front">
        <div
          className="tour__img "
          style={{
            backgroundImage: `url(${props.tour__img})`,
          }}
        ></div>
        <div className="tour__content">
          <div className="tour__heading">
            <span
              className="tour__heading--span"
              style={{ backgroundImage: `${colorListCss[props.tour__color]}` }}
            >
              {props.tour__heading}
            </span>
          </div>
          <ul className="tour__list">{listItems}</ul>
        </div>
      </div>
      <div
        className="tour__container--back "
        style={{ backgroundImage: `${colorListCss[props.tour__color]}` }}
      >
        <div className="tour__content--back">
          <div className="tour__content--price">{props.tour__price}</div>
          <div className="tour__content--button">
            <MainButton
              btn__name="Explore"
              btn__href={props.tour__btn__href}
              btn__scroll={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
TourBox.defaultProps = {
  tour__img: nat4,
  tour__color: "red",
  tour__heading: "TBC",
  tour__list: [
    "3 day tour",
    "upto 30 peoples",
    "2 tour guides",
    "sleep in cozy hostel",
    "difficult:easy",
  ],
  tour__price: 320,
};
export default TourBox;
