import React from "react";
import Header from "./Header/Header";
import About from "./About/About";
import Products from "./Products/Products";
import Features from "./Features/Features";
import Tours from "./Tours/Tours";
import Stories from "./Stories/Stories";
import Booking from "./Booking/booking";
import Footer from "./Footer/Footer";
import PopupBox from "../components/popup-box/PopupBox";
import Navigation from "../components/navigation/Navigation";
import ContactUsbox from "../components/contact-us-box/ContactUsbox";
import AboutBaaniBox from "../components/about-baani-box/AboutBaaniBox";
import AboutVaskkBox from "../components/about-vaskk-box/AboutVaskkBox";
import AboutTtsBox from "../components/about-tts-box/AboutTtsBox";
import CompanyBox from "../components/company-box/CompanyBox";
import CareersBox from "../components/careers-box/CareersBox";
import PrivacyBox from "../components/privacy-box/PrivacyBox";
import Baani from "../baani/Baani.js";

function index() {
  return (
    <>
      <Navigation />
      <Header />
      <About />
      <Features />
      <Products />
      <PopupBox />
      <ContactUsbox />
      <AboutBaaniBox />
      <AboutVaskkBox />
      <AboutTtsBox />
      <CompanyBox />
      <CareersBox />
      <PrivacyBox />
      <Tours />
      <Stories />
      <Booking />
      <Footer />
    </>
  );
}

//<Baani />

export default index;
