import React from "react";
import "./stories.css";
import Story from "../../components/story/Story";
import marrysmithimg from "../../assets/img/nat-8.jpg";
import pranavmimg from "../../assets/img/nat-9.jpg";
import vdomp4 from "../../assets/video/video.mp4";

function Stories() {
  return (
    <section id="stories">
      <div className="stories">
        <div className="stories__container--video">
          <video
            className="container--video"
            muted={true}
            autoPlay={true}
            playsInline={true}
            loop
          >
            <source src={vdomp4} />
          </video>
        </div>
        <div className="stories__main-cont" height="100rm">
          <h3
            style={{
              color: "black",
              textTransform: "none",
            }}
          >
            AI generated identity
          </h3>
          <div className="stories__container"></div>
        </div>
      </div>
    </section>
  );
}

export default Stories;
