import styled from "styled-components";

const VideoBox = styled.div`
  //position: relative;
  position: float;
  margin-left: 60px;
  margin-top: 7px;
`;

export default VideoBox;
