import React from "react";
import "./companyBox.css";
import img1 from "../../assets/img/UNIWEV1.png";
import img2 from "../../assets/img/nat-8.jpg";
import MainButton from "../buttons/MainButton";
import { IoClose } from "react-icons/io5";

function CompanyBox() {
    return (
        <div className="popup-Container" id="companyBox">
            <div className="popupgrid">
                <a className="popup__close-btn" href="#footer">
                    <IoClose />
                </a>
                <div className="popup__image--container">
                    <img src={img1} alt="image1" />
                </div>
                <div className="popup__description">
                    <h2 style={{ color: "grey", textTransform: "none" }}></h2>

                    <p>
                        UniWev is an Artificial Intelligence based software
                        product development company. UniWev develops interactive
                        solutions for future world and future people based on
                        virtual universes, virtual humans and avatars.
                        <br></br>
                        <br></br>
                        UniWev creates virtual human based solutions in
                        following domains:
                        <br></br>
                        {"\u00A0"}
                        {"\u00A0"} - Education and Learning
                        <br></br> {"\u00A0"}
                        {"\u00A0"} - Digital humans and avatars
                        <br></br> {"\u00A0"}
                        {"\u00A0"} - Animation, character personalization
                        <br></br> {"\u00A0"}
                        {"\u00A0"} - Immersive virtual reality
                        <br></br>
                        <br></br>UniWev products are developed using the
                        Artificial Intelligence based platrform called baani.
                        Refer to baani platform website baani.ai for details. .{" "}
                        <br></br> <br></br>
                        <p>For queries contact us at:</p>
                        Email: team@uniwev.com
                        <br></br> Phone: +91 9011051697
                    </p>
                    <MainButton
                        btn__bgcolor="var(--primary-color)"
                        btn__color="#fff"
                        btn__name="Explore !"
                    />
                </div>
            </div>
        </div>
    );
}

export default CompanyBox;
