import React from "react";
import styles from "./Header.module.css";
//import logo from "../../assets/img/vaskk-white.png";
import logo from "../../assets/img/UNIWEV.png";
import MainButton from "../../components/buttons/MainButton";
import vdomp4 from "../../assets/video/Catherine.mp4";

function header() {
  return (
    <>
      <header className={styles.header} id="home">
        <div className={styles.header_container}>
          <nav className={styles.navigation}>
            <div className={styles.logo}>
              <img src={logo} alt="logo" />
            </div>
            <div className={`${styles["nav-item"]}`}></div>
          </nav>
        </div>

        <div className={styles.head__main_cont}>
          <h3 className={styles.right__anim}>
            Talk to users with your avataars
          </h3>
          <MainButton
            btn__name="Digital Humans"
            btn__animation={true}
            btn__href="tours"
          />
          <video
            muted={true}
            autoPlay={true}
            playsInline={true}
            loop
            transpare
            width="100%"
          >
            <source src={vdomp4} />
          </video>
        </div>
      </header>
    </>
  );
}

export default header;
