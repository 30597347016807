import React from "react";
import "./about.css";
//import img1 from "../../assets/img/AWSCertifications.jpg";
//import img2 from "../../assets/img/AzureCertifications2.png";
//import img3 from "../../assets/img/GCP_certifications.jpg";
import img2 from "../../assets/img/Camila.png";
import img1 from "../../assets/img/Catherine.png";
import img3 from "../../assets/img/Male.png";
import SecondButton from "../../components/buttons/SecondButton";

function About() {
  return (
    <section className="about" id="about">
      <div className="container about__container">
        <h2 className="about__heading">Your digital avataars</h2>
        <div className="about__container--main">
          <div className="about__para">
            <h4>Say hello to Digital People</h4>
            <p>
              Interact with your customers and users using digital humans.
              Shaping consumers' online buying/ browsing behaviour by creating
              immersive, interactive experiences and reimagining the visual
              discovery
            </p>
            <h4>Your organization avataars serving your users</h4>
            <p>
              No need to ask your customers to come to platforms like Metaverse
              or Omniverse.
            </p>
            <SecondButton btn__name="Learn more →" />
          </div>
          <div className="about__imges">
            <div className="about__img">
              <img className="about__img--1" src={img1} alt="images" />
              <img className="about__img--2" src={img2} alt="images" />
              <img className="about__img--3" src={img3} alt="images" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
