import React from "react";
import "./tours.css";
import TourBox from "../../components/tour-box/TourBox";
import seaimg from "../../assets/img/baani.jpg";
import forestimg from "../../assets/img/vaskk.png";
import snowimg from "../../assets/img/cloud.png";
import MainButton from "../../components/buttons/MainButton";

function Tours() {
  return (
    <section id="tours">
      <div className="container tours__container">
        <div className="tours__grid">
          <TourBox
            tour__btn__href="#aboutBaani"
            tour__color="black"
            tour__img={seaimg}
            tour__heading="baani.ai"
            tour__content--only="baani.ai"
            tour__price="baani.ai"
            tour__list={[
              <p style={{ textTransform: "none" }}>
                Baani is a facial converstational platform from Uniwev. The
                products vaskk.com, theTekSchool.com are developed on platform
                Baani to serve different use cases.
              </p>,
            ]}
          />
          <TourBox
            tour__btn__href="#aboutVaskk"
            tour__color="green"
            tour__img={forestimg}
            tour__heading="VAskk.com"
            tour__price="VAskk.com"
            tour__list={[
              <p style={{ textTransform: "none" }}>
                Vask is a B2B component developed on platform Baani. Vaskk
                allows enterprise to reach out to the end users and communicate
                with them with their Avataars.
              </p>,
            ]}
          />

          <div className="tours__grid-last-child">
            <TourBox
              tour__btn__href="#aboutTts"
              tour__color="blue"
              tour__img={snowimg}
              tour__heading="theTekSchool.com"
              tour__price="theTekSchool.com"
              tour__list={[
                <p style={{ textTransform: "none" }}>
                  An educatoinal platform which provides interactive and
                  personalized learning experience to the students and
                  professionals. No human intervention required on th platform.{" "}
                </p>,
              ]}
            />
          </div>
        </div>
        <div className="tours__button">
          <MainButton
            btn__name="Give human touch to your digital channels"
            btn__bgcolor="var(--primary-color) "
            btn__color="#fff"
          />
        </div>
      </div>
    </section>
  );
}

export default Tours;
