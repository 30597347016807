import React from "react";
import "./aboutVaskkBox.css";
import img1 from "../../assets/img/vaskk-theme.png";
import MainButton from "../buttons/MainButton";
import { IoClose } from "react-icons/io5";

function AboutVaskkBox() {
  return (
    <div className="popup-Container" id="aboutVaskk">
      <div className="popupgrid">
        <a className="popup__close-btn" href="#tours">
          <IoClose />
        </a>
        <div className="popup__image--container">
          <img src={img1} alt="image1" />
        </div>
        <div className="popup__description">
          <h2 style={{ color: "grey", textTransform: "none" }}>Vaskk.com</h2>
          <p>
            Vask is a B2B component developed on platform Baani. Vaskk allows
            enterprise to reach out to the end users and communicate with them
            with their Avataars..{" "}
          </p>
          <MainButton
            btn__bgcolor="var(--primary-color)"
            btn__color="#fff"
            btn__name="Explore More"
          />
        </div>
      </div>
    </div>
  );
}

export default AboutVaskkBox;
