import React from "react";
import "./careersBox.css";
import img1 from "../../assets/img/UNIWEV1.png";
import img2 from "../../assets/img/nat-8.jpg";
import MainButton from "../buttons/MainButton";
import { IoClose } from "react-icons/io5";

function CareersBox() {
  return (
    <div className="popup-Container" id="careersBox">
      <div className="popupgrid">
        <a className="popup__close-btn" href="#footer">
          <IoClose />
        </a>
        <div className="popup__image--container">
          <img src={img1} alt="image1" />
        </div>
        <div className="popup__description">
          <h2 style={{ color: "grey", textTransform: "none" }}>Careers</h2>

          <p>
            At UniWev we have following open roles: <br></br>
            <li>Front End developer with strong Java Script knowledge</li>
            <li>Back end developer with Python experience with ML libraries</li>
          </p>

          <h4 style={{ color: "grey", textTransform: "none" }}>
            Write to us at following email id if you are interested to work:{" "}
            Email: team@uniwev.com
            <br></br> Phone: +91 9011051697
          </h4>
          <MainButton
            btn__bgcolor="var(--primary-color)"
            btn__color="#fff"
            btn__name="Explore !"
          />
        </div>
      </div>
    </div>
  );
}

export default CareersBox;
