import React from "react";
import "./aboutTtsBox.css";
import img1 from "../../assets/img/baani-theme4.png";
import MainButton from "../buttons/MainButton";
import { IoClose } from "react-icons/io5";

function AboutTtskBox() {
  return (
    <div className="popup-Container" id="aboutTts">
      <div className="popupgrid">
        <a className="popup__close-btn" href="#tours">
          <IoClose />
        </a>
        <div className="popup__image--container">
          <img src={img1} alt="image1" />
        </div>
        <div className="popup__description">
          <h2 style={{ color: "grey", textTransform: "none" }}>
            theTekSchool.com
          </h2>
          <p>
            An educatoinal platform which provides interactive and personalized
            learning experience to the students and professionals. No human
            intervention required on th platform.{" "}
          </p>
          <MainButton
            btn__bgcolor="var(--primary-color)"
            btn__color="#fff"
            btn__name="Explore More"
          />
        </div>
      </div>
    </div>
  );
}

export default AboutTtskBox;
