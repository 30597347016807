import React from "react";
import "./aboutBaaniBox.css";
import img1 from "../../assets/img/baani-image1.png";
import img2 from "../../assets/img/baani-image2.png";
import MainButton from "../buttons/MainButton";
import { IoClose } from "react-icons/io5";

function AboutBaaniBox() {
  return (
    <div className="popup-Container" id="aboutBaani">
      <div className="popupgrid">
        <a className="popup__close-btn" href="#tours">
          <IoClose />
        </a>
        <div className="popup__image--container">
          <img src={img2} alt="image2" />
        </div>
        <div className="popup__description">
          <h2 style={{ color: "grey", textTransform: "lowercase" }}>
            baani.ai
          </h2>
          <h4>Baani</h4>
          <p>
            Baani is a facial converstational platform from Uniwev. The products
            vaskk.com, theTekSchool.com are developed on platform Baani to serve
            different use cases.{" "}
          </p>
          <MainButton
            btn__bgcolor="var(--primary-color)"
            btn__color="#fff"
            btn__name="Explore More"
          />
        </div>
      </div>
    </div>
  );
}

export default AboutBaaniBox;
