import React from "react";
import "./features.css";
import FeatureBox from "../../components/feature-box/FeatureBox";
import { IoEarth } from "react-icons/io5";
import { FaAws } from "react-icons/fa";
import { FcCustomerSupport } from "react-icons/fc";
import { FcAndroidOs } from "react-icons/fc";
import { GiTeacher } from "react-icons/gi";

import { SiMicrosoftazure } from "react-icons/si";
import { FaGoogle } from "react-icons/fa";
import { FcConferenceCall } from "react-icons/fc";

import { IoCompassOutline } from "react-icons/io5";
import { IoSubwayOutline } from "react-icons/io5";
import { IoHeartOutline } from "react-icons/io5";

function Features() {
  return (
    <section id="features">
      <div className="container features__container">
        <div className="features__grid">
          <FeatureBox
            heading="On your website"
            icon={<FcCustomerSupport />}
            content="Digital avataars talking to your customers to understand and resolve their queries."
          />
          <FeatureBox
            heading="On your mobile app"
            icon={<FcAndroidOs />}
            content="On your mobile application, your avataars engaged in meaningful diagogue with your users."
          />
          <FeatureBox
            heading="Online Eductaion"
            icon={<GiTeacher />}
            content="Digital animated characters interact with the school kids to provide curated learning experience. 
            Digital humans teaching professional courses."
          />
          <FeatureBox
            heading="Virtual Universe"
            icon={<FcConferenceCall />}
            content="Digital humans serving your customers on your website, mobile app, metaverse or other virtual universe."
          />
        </div>
      </div>
    </section>
  );
}

export default Features;
