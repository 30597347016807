import React from "react";
import "./products.css";
//import img1 from "../../assets/img/AWSCertifications.jpg";
//import img2 from "../../assets/img/AzureCertifications2.png";
//import img3 from "../../assets/img/GCP_certifications.jpg";

import SecondButton from "../../components/buttons/SecondButton";
import MainButton from "../../components/buttons/MainButton";

function Products() {
  return (
    <div className="container tours__container">
      <div className="tours__button">
        <MainButton
          btn__name="Our Products"
          btn__bgcolor="var(--primary-color) "
          btn__color="#fff"
        />
      </div>
    </div>
  );
}

export default Products;
