import RoutesIndex from "./routes/RoutesIndex";
import "./app.css";
function App() {
  return (
    <>
      <RoutesIndex />
    </>
  );
}

export default App;
