import styled from "styled-components";

const Footer = styled.div`
  //position: relative;
  position: absolute;
  bottom: 0;
  width: 100%;
`;

export default Footer;
