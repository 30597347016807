import React from "react";
import "./contactUsbox.css";
import img1 from "../../assets/img/UNIWEV1.png";
import img2 from "../../assets/img/nat-8.jpg";
import MainButton from "../buttons/MainButton";
import { IoClose } from "react-icons/io5";

function ContactUsbox() {
  return (
    <div className="popup-Container" id="contactus">
      <div className="popupgrid">
        <a className="popup__close-btn" href="#footer">
          <IoClose />
        </a>
        <div className="popup__image--container">
          <img src={img1} alt="image1" />
        </div>
        <div className="popup__description">
          <h2 style={{ color: "grey", textTransform: "none" }}>
            Contact UniWev Team
          </h2>
          <p>For queries contact us at:</p>
          Email: team@uniwev.com
          <br></br> Phone: +91 9011051697
          <MainButton
            btn__bgcolor="var(--primary-color)"
            btn__color="#fff"
            btn__name="Explore !"
          />
        </div>
      </div>
    </div>
  );
}

export default ContactUsbox;
