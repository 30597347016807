import React from "react";
import "./privacyBox.css";
import img1 from "../../assets/img/UNIWEV1.png";
import img2 from "../../assets/img/nat-8.jpg";
import MainButton from "../buttons/MainButton";
import { IoClose } from "react-icons/io5";

function PrivacyBox() {
  return (
    <div className="popup-Container" id="privacyBox">
      <div className="popupgrid">
        <a className="popup__close-btn" href="#footer">
          <IoClose />
        </a>
        <div className="popup__image--container">
          <img src={img1} alt="image1" />
        </div>
        <div className="popup__description">
          <h2 style={{ color: "grey", textTransform: "none" }}>
            Privacy Policy
          </h2>

          <p>
            UniWev and the UniWev logo are registered trademarks or trademarks
            of UniWev in the United States and/or other countries. All other
            trademarks are the property of their respective owners.
          </p>
        </div>
      </div>
    </div>
  );
}

export default PrivacyBox;
