import React from "react";
import "./footer.css";
import footerLogoL1x from "../../assets/img/UNIWEV.png";
import footerLogoL2x from "../../assets/img/UNIWEV.png";
import footerLogoS1x from "../../assets/img/UNIWEV.png";
import footerLogoS2x from "../../assets/img/UNIWEV.png";

function Footer() {
  return (
    <div className="  footer__container " id="footer">
      <div className="footer__grid">
        <div className="footer__menu">
          <div className="footer__menu--container">
            <ul className="footer__menu--list">
              <li>
                <a href="#contactus">Contact Us</a>
              </li>
              <li>
                <a href="#companyBox">Company</a>
              </li>
              <li>
                <a href="#careersBox">Careers</a>
              </li>
              <li>
                <a href="#privacyBox">Privacy policy</a>
              </li>
            </ul>
          </div>
        </div>
        <picture className="footer__logo">
          <source
            srcSet={`${footerLogoL1x} 1x,${footerLogoL2x} 2x`}
            alt="footerlogo"
            media="(min-width:600px)"
          />

          <img srcSet={`${footerLogoS1x} 1x,${footerLogoS2x}`} />
        </picture>
      </div>
    </div>
  );
}

export default Footer;
